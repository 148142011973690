/**
 * Inservice Card
 * Inservice Card API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';

// @ts-ignore
import { CardFlowDto } from '../model/cardFlowDto';
// @ts-ignore
import { GetCardFlowDto } from '../model/getCardFlowDto';
// @ts-ignore
import { HomeDto } from '../model/homeDto';
// @ts-ignore
import { ProcessCardFlowDto } from '../model/processCardFlowDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class PatientCardsService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param xTENANTID 
   * @param getCardFlowDto 
   */
  public async cardPatientControllerGetCardFlow(xTENANTID: string, getCardFlowDto: GetCardFlowDto, ): Promise<CardFlowDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling cardPatientControllerGetCardFlow.');
    }
    if (getCardFlowDto === null || getCardFlowDto === undefined) {
      throw new Error('Required parameter getCardFlowDto was null or undefined when calling cardPatientControllerGetCardFlow.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/card/card-patient/get-card-flow`,
      data: getCardFlowDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   */
  public async cardPatientControllerHome(xTENANTID: string, ): Promise<HomeDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling cardPatientControllerHome.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/card/card-patient/home`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param cardId 
   */
  public async cardPatientControllerMarkCompleted(xTENANTID: string, cardId: string, ): Promise<any> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling cardPatientControllerMarkCompleted.');
    }
    if (cardId === null || cardId === undefined) {
      throw new Error('Required parameter cardId was null or undefined when calling cardPatientControllerMarkCompleted.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/card/card-patient/dismiss/${this.configuration.encodeParam({name: "cardId", value: cardId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

  /**
   * @param xTENANTID 
   * @param processCardFlowDto 
   */
  public async cardPatientControllerProcessCardFlow(xTENANTID: string, processCardFlowDto: ProcessCardFlowDto, ): Promise<CardFlowDto> {
    if (xTENANTID === null || xTENANTID === undefined) {
      throw new Error('Required parameter xTENANTID was null or undefined when calling cardPatientControllerProcessCardFlow.');
    }
    if (processCardFlowDto === null || processCardFlowDto === undefined) {
      throw new Error('Required parameter processCardFlowDto was null or undefined when calling cardPatientControllerProcessCardFlow.');
    }

    const localVarHeaders = this.defaultHeaders;
    if (xTENANTID) {
      localVarHeaders['X-TENANT-ID'] = String(xTENANTID);
    }

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;

    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/card/card-patient/process-card-flow`,
      data: processCardFlowDto,
      headers: localVarHeaders,
      responseType: 'json',
    });
    if (response?.status >= 400) {
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    }
    return response.data;

  }

}
